
// Modernizr Test for Text Stroke, used on H1s
Modernizr.addTest('textstroke', function() {
	var h1 = document.createElement('h1');
	return !(!('webkitTextStroke' in h1.style) && !('textStroke' in h1.style));
});

(function($){

	window.seaState = {};

	var SEASTATE = window.seaState;

	var $window = $(window);

	SEASTATE.init = function(){ 
		SEASTATE.setElements();
		SEASTATE.days();
		SEASTATE.activeLink();
		SEASTATE.popUp();
		SEASTATE.matchHeight();
		SEASTATE.showNav();
		SEASTATE.programNav();
		SEASTATE.faqToggle();
		// SEASTATE.skrollr();
	};

	SEASTATE.setElements = function(){
		SEASTATE.elems             = {};

		SEASTATE.elems.href        = window.location.pathname;
		SEASTATE.elems.previousUrl = document.referrer;

	};

	SEASTATE.matchHeight = function() {
		$('.match-height').matchHeight();
	};

	SEASTATE.showNav = function() {
		
		var navTrigger = $('.nav-trigger');
		var nav = $('.main-navigation');

		navTrigger.on('click', function() {
			var $this = $(this);

			$this.toggleClass('active');
			nav.toggleClass('active');
		});

	};	

	SEASTATE.programNav = function() {
		
		if (!$('.program-nav').length) { return; }

		$('#program-nav').onePageNav({
			scrollThreshold: 0.2,
			filter: ':not(.exclude-from-nav)'
		});

		/* -------------- By default, the plugin doesn't scroll to the first item, this does this ---------------- */

		$('#program-nav-1 a').click(function() {
			// grab the html document and / or the body
			$("html, body").animate({
			// grab the item clicked's href and get the distance between this element
			//and the element it is scrolling to
				scrollTop: $($(this).attr("href")).offset().top + "px"
			}, {
				duration: 750,
				easing: "swing"
			});
			return false;
		});		


		/* -------------- Controls when the program nav gets sticky ---------------- */


		var programNav = $('.program-nav');
		var firstPanel = $('#itinerary');

		// this creates an element, that will fill the space of .pagination, when its sticky.
		// this prevents the content from jumping up when the .pagination gets the .sticky class added
		$('<div class="program-nav-placeholder"></div>').insertAfter(programNav);

		var programNavPlaceholder = $('.program-nav-placeholder');
		var programNavPosition = firstPanel.offset().top;

		// updates the size of the pagination-placeholder, as well as the offset.top value
		window.onresize = function() {
			programNavPosition = firstPanel.offset().top;
		};

		// using vanilla js here for better performance, reduced lag on scroll
		window.onscroll = function() {

			// using vanilla js here (scrollY) for better performance, reduced lag on scroll
			if (window.pageYOffset > programNavPosition) {
				programNav.addClass('sticky');
				programNavPlaceholder.addClass('sticky');
			} else {
				programNav.removeClass('sticky');
				programNavPlaceholder.removeClass('sticky');
			}

		};		

	};		

	SEASTATE.faqToggle = function() {
    
	  var allPanels = $('.faq-item > dd').hide();
    
	  $('.faq-item > dt').click(function() {
	      $this = $(this);
	      $target =  $this.next();

	      if(!$target.hasClass('active')){
	         // allPanels.removeClass('active').slideUp(100);
	         $target.addClass('active').slideDown(100);
	         $target.parent().addClass('active');
	      }
	      
	    return false;
	  });

	};	

	SEASTATE.skrollr = function(){

		// var s = skrollr.init({
	//  mobileCheck: function() {
		//      //hack - forces mobile version to be off
		//      return false;
		//  }
		// });

	};


	SEASTATE.popUp = function() {

		$('.open-popup-link').magnificPopup({
			removalDelay: 500, //delay removal by X to allow out-animation
			callbacks: {
				beforeOpen: function() {
					this.st.mainClass = this.st.el.attr('data-effect');
				}
			},
			midClick: true // allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source.
		});

	};


	SEASTATE.days = function(){

		$('.owl-carousel').owlCarousel({
				loop:true,
				margin:0,
				nav:true,
				items: 1
		});

	};


	SEASTATE.activeLink = function(){

	 var links = $('.nav a');

	 links.each(function() {
			var $this = $(this);

			if ($this.attr('href') == SEASTATE.elems.href) {
				$this.addClass('active');
			}

	 });

	};




$window.load(function(){

});

$(document).ready(function(){

	SEASTATE.init();

});//close document ready


})(window.jQuery);
